import { Background, Container, Title } from './style';
export const About = () => {
    return (
        <Background>
            <Container>
                <Title></Title>
            </Container>
        </Background>
    );
};
